<template>
  <v-card>
    <v-card-title class="px-0 pb-0">
      <v-container class="px-0 py-0 mb-2">
        <v-row align="end" class="px-8">
          <v-col cols="auto">
            <v-btn
              height="29"
              class="label text-capitalize font-weight-regular"
              depressed
              color="secondary"
              :ripple="false"
            >
              {{ $t('page_company_fl_goal_setting_title') }}
            </v-btn>
          </v-col>

          <v-spacer></v-spacer>

          <v-col cols="auto">
            <v-btn
              text
              color="primary"
              class="text-capitalize"
              to="fl-goal/create"
            >
              <v-icon left>mdi-plus</v-icon>
              {{ $t('page_company_fl_goal_setting_create_text') }}
            </v-btn>
          </v-col>

          <v-col cols="auto">
            <v-btn
              icon
              @click="toggleExpand"
              :class="isExpanded ? 'rotate' : ''"
            >
              <v-icon size="16">$customChevronDown</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-title>

    <v-divider></v-divider>

    <v-card-text class="pb-0">
      <v-container>
        <v-row>
          <v-col cols="12" class="text-reference-sub">
            {{ $t('reference') }} | {{ getTermLabel() }}
          </v-col>

          <v-col cols="12">
            <v-row class="px-8" align="end">
              <v-col cols="3" class="py-0">
                <v-row align="center">
                  <v-col cols="3" class="text-right">
                    <div
                      class="text-subheading-2 font-weight-regular text-right secondary--text"
                    >
                      {{ $t('fl_cost') }}
                    </div>
                  </v-col>
                  <v-col cols="auto">
                    <div
                      class="text-subheading font-weight-regular secondary--text"
                    >
                      {{ getCompanyFLGoals.fl_costs.total.amount | toCurrency }}
                    </div>
                  </v-col>
                  <v-col cols="auto">
                    <v-chip
                      class="font-weight-regular chip-custom"
                      small
                      color="rgba(88, 88, 88, 0.15)"
                      text-color="secondary"
                      :ripple="false"
                    >
                      {{ getCompanyFLGoals.fl_costs.total.percentage }}%
                    </v-chip>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="3" class="py-0">
                <v-row align="center">
                  <v-col cols="3" class="text-right">
                    <div
                      class="text-subheading-2 font-weight-regular text-right secondary--text"
                    >
                      {{ $t('food') }}
                    </div>
                  </v-col>
                  <v-col cols="auto">
                    <div
                      class="text-subheading font-weight-regular secondary--text"
                    >
                      {{
                        getCompanyFLGoals.fl_costs.f_cost.amount | toCurrency
                      }}
                    </div>
                  </v-col>
                  <v-col cols="auto">
                    <v-chip
                      class="font-weight-regular chip-custom"
                      small
                      color="rgba(88, 88, 88, 0.15)"
                      text-color="secondary"
                      :ripple="false"
                    >
                      {{ getCompanyFLGoals.fl_costs.f_cost.percentage }}%
                    </v-chip>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="3" class="py-0">
                <v-row align="center">
                  <v-col cols="3" class="text-right">
                    <div
                      class="text-subheading-2 font-weight-regular text-right secondary--text"
                    >
                      {{ $t('labor') }}
                    </div>
                  </v-col>
                  <v-col cols="auto">
                    <div
                      class="text-subheading font-weight-regular secondary--text"
                    >
                      {{
                        getCompanyFLGoals.fl_costs.l_cost.amount | toCurrency
                      }}
                    </div>
                  </v-col>
                  <v-col cols="auto">
                    <v-chip
                      class="font-weight-regular chip-custom"
                      small
                      color="rgba(88, 88, 88, 0.15)"
                      text-color="secondary"
                      :ripple="false"
                    >
                      {{ getCompanyFLGoals.fl_costs.l_cost.percentage }}%
                    </v-chip>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <v-card-text class="px-0">
      <v-expansion-panels v-model="expanded" accordion class="elevation-0">
        <v-expansion-panel :key="0">
          <v-expansion-panel-content eager>
            <Table
              :loading="loading"
              :headers="headers"
              :items="flGoals"
              :footer="false"
              :total-records="flGoals.length"
              :number-of-pages="0"
              @update:options="getDataFromApi"
            >
              <template v-slot:item.is_active="{ item }">
                <template v-if="item.is_active">
                  <v-chip v-if="item.is_active" color="#D9FFEA" small>
                    <v-icon color="success" size="16">$check</v-icon>
                  </v-chip>
                </template>
              </template>

              <template v-slot:item.id="{ item }">
                <span class="primary--text font-weight-medium">
                  {{ item.id }}
                </span>
              </template>

              <template v-slot:item.actions="{ item }">
                <v-btn
                  v-if="!item.is_active"
                  color="#FFE0E0"
                  icon
                  small
                  @click="deleteGoal(item.id)"
                >
                  <v-icon color="#EC6767" size="16">$cross</v-icon>
                </v-btn>
              </template>
            </Table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>
  </v-card>
</template>

<script>
import Table from '@/components/admin/partials/Table/Table'
import { mapGetters } from 'vuex'

export default {
  name: 'FLSetting',
  components: { Table },
  props: {
    term: {
      type: Number,
      default: 0,
      required: true
    }
  },
  watch: {
    term: {
      handler(val) {
        if (val !== 0) this.getDataFromApi()
      }
    }
  },
  data() {
    return {
      loading: false,
      expanded: 0,
      headers: [
        {
          text: '',
          align: 'right',
          value: 'is_active',
          sortable: false,
          width: 50
        },
        { text: this.$t('id'), align: 'left', value: 'id', width: 100 },
        {
          text: this.$t(
            'page_company_fl_goal_setting_table_header_f_percentage'
          ),
          align: 'center',
          value: 'f_percentage',
          sortable: false
        },
        {
          text: this.$t(
            'page_company_fl_goal_setting_table_header_l_percentage'
          ),
          align: 'center',
          value: 'l_percentage',
          sortable: false
        },
        {
          text: this.$t(
            'page_company_fl_goal_setting_table_header_fl_percentage'
          ),
          align: 'center',
          value: 'fl_percentage',
          sortable: false
        },
        {
          text: this.$t('page_company_fl_goal_setting_table_header_registered'),
          align: 'center',
          value: 'registered',
          sortable: false
        },
        {
          text: this.$t('page_company_fl_goal_setting_table_header_by'),
          align: 'center',
          value: 'by',
          sortable: false
        },
        { text: '', align: 'center', value: 'actions', sortable: false }
      ]
    }
  },
  computed: {
    ...mapGetters(['getCompanyFLGoals']),
    isExpanded() {
      return this.expanded === 0
    },
    flGoals() {
      return this.getCompanyFLGoals.fl_ratios.map(goal => {
        return {
          id: goal.id,
          f_percentage: `${goal.f_ratio}%`,
          l_percentage: `${goal.l_ratio}%`,
          fl_percentage: `${goal.f_ratio + goal.l_ratio}%`,
          is_active: goal.status === 1,
          registered: goal.created_at,
          by: `${goal.user.last_name} ${goal.user.first_name}`
        }
      })
    }
  },
  methods: {
    toggleExpand() {
      if (this.expanded === -1) {
        this.expanded = 0
      } else {
        this.expanded = -1
      }
    },
    getTdAlignClass(align) {
      return align !== null ? 'text-' + align : ''
    },
    getDataFromApi(e) {
      this.loading = true
      let params = {}
      params.term = this.term
      if (e?.sortBy[0] !== undefined) {
        let order = e.sortDesc[0] ? 'desc' : 'asc'
        params.sort = `${e.sortBy[0]}:${order}`
      }
      this.loading = true
      this.$store
        .dispatch('COMPANY_FL_GOAL_LIST_GET', {
          id: this.$route.params.id,
          params: params
        })
        .finally(() => {
          this.loading = false
        })
    },
    deleteGoal(id) {
      return this.$emit('delete', {
        company_id: this.$route.params.id,
        id: id,
        type: 'fl'
      })
    },
    getTermLabel() {
      let businessYear = this.getCompanyFLGoals.business_year
      return this.$t('year_label', {
        number: businessYear.term,
        start: businessYear.start_date.slice(0, -3),
        end: businessYear.end_date.slice(0, -3)
      })
    }
  }
}
</script>

<style lang="scss" src="./FLSetting.scss" scoped></style>
